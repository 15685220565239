<script>
import api from '@/command/api'
import DetailFormGroup from '../../../../components/DetailFormGroup'
import { getAction } from '../../../../command/netTool'

const handleDeep = function (data) {
  return data.map((e) => {
    return {
      ...e,
      title: e.name,
      value: e.id,
      key: e.id,
      children: e.children && e.children.length > 0 && handleDeep(e.children),
    }
  })
}
export default {
  name: 'detailAdd',
  data() {
    return {
      ...api.command.getState(),
      reallyDetail: {},
      deptTree: [],
    }
  },
  mounted() {
    getAction('/api/dept/tree').then((e) => {
      this.deptTree = e.data && e.data.length && handleDeep(e.data)
    })
    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: `/account/sys/info/${id}`,
        })
        .then((result) => {
          this.reallyDetail = result
        })
  },
  methods: {
    getForm1() {
      return {
        title: '用户信息',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '关联机构',
            type: 'treeSelect',
            cols: 12,
            key: 'deptIds',
            // rules: [
            //   {
            //     required: true,
            //     type: 'string'
            //   }
            // ],
            isCheckable: false,
            typeData: this.deptTree,
            onChange: (value, label, extra) => {
              this.detail.deptName = label[0]
            },
          },
          {
            name: '用户名',
            type: 'input',
            cols: 12,
            key: 'username',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
            disabled: this.reallyDetail.userId,
          },
          {
            name: '手机号码（登录账号）',
            type: 'input',
            cols: 12,
            key: 'phone',
            rules: [
              {
                required: true,
                type: 'string',
              },
              {
                ruleType: 'phone',
                message: '请输入正确手机格式',
              },
            ],
            disabled: this.reallyDetail.userId,
          },

          {
            name: '真实姓名',
            type: 'input',
            cols: 12,
            key: 'realName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '昵称',
            type: 'input',
            cols: 12,
            key: 'nickName',
            // rules: [
            //   {
            //     required: true,
            //     type: 'string'
            //   }
            // ]
          },
          {
            name: '性别',
            type: 'radioButton',
            cols: 12,
            key: 'gender',
            typeData: [
              {
                name: '男',
                value: '1',
              },
              {
                name: '女',
                value: '2',
              },
            ],
          },
          //   {
          //     name: '邮箱地址',
          //     type: 'input',
          //     cols: 12,
          //     key: 'email'
          //   }
        ],
      }
    },

    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: (data) => {
            let lxfs = /^[0-9]*$/
            if (!lxfs.test(data.phone)) {
              this.$message.warning('请正确输入电话号码！')
              return false
            }

            // let lxf = /^[a-zA-Z1-9_]\w+@\w+.(com|cn)/
            // if (!lxf.test(data.email)) {
            //   this.$message.warning('请正确输入邮箱地址！')
            //   return false
            // }

            api.command.createDetail.call(this, {
              url: `/admin/account/sys/${data.userId ? 'update' : 'save'}`,
              params: { ...data },
              host: '/bpi',
            })
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/rights/user/bodyMain')
          },
        },
      ]

      return {
        left,
      }
    },
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} form={this.reallyDetail} data={[this.getForm1()]} />
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
